@import "node_modules/@fluentui/react/dist/sass/Fabric";

/* Shell Primary Palette */
$shell-color-red: #D01B1E; //#DD1D21;
$shell-color-green: #008040; //#008443;
$shell-color-yellow: #FBCE07;
$shell-color-lightyellow: #FDF6D3;
$shell-color-blue: #003C88;

$shell-grid-leader: #fff7cf;
$shell-grid-member: #ffffff;
$shell-grid-floating: #eee1f4;

/* ShellSecondary Palette */
$shell-color-midblue: #009EB4;
$shell-color-orange: #EB8705;

$shell-color-grey1: #D9D9D9;
$shell-color-grey2: #A6A6A6;
$shell-color-grey3: #595959;
$shell-color-grey4: #404040;
$shell-color-white: #F7F7F7;


// Overrides fluent colors
// https://developer.microsoft.com/en-us/fluentui#/styles/web/colors/theme-slots#implementation
// node_modules\@fluentui\react\dist\sass\variables\_Color.Variables.MDL2.scss
$ms-color-themePrimary: $shell-color-red;
$ms-color-themeSecondary: $shell-color-yellow;
$ms-color-themeTertiary: $shell-color-midblue;
$ms-color-neutralPrimary: #323130;


