@import "src/sass/_variables";
@import "src/sass/_colors";

.filterWrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row !important;
    flex-grow: 0 !important;
    gap: 0 $spacer;

    h2 {
        width: 100%;
        button {
            float: right;
            margin: calc(-1 * ($spacer / 2));
            cursor: pointer;
        }
    }
}

// .ms-Callout-main > div {
// .ms-Dropdown-callout {
//     max-height: 270px !important;
// }

.ms-Dropdown-optionText {
    padding: 0 7px;
}

.searchBoxInDropdown {
    position: absolute;
    top: 0;
    left: 0px;
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    border: 1px solid #d3d3d3;
    padding: 0 7px;
}

.dropdown-item {
    width: inherit;
    padding: 0 7px;
    text-wrap: nowrap;

    &.add-new {
        .ms-Button--icon,
        .addIcon {
            position: absolute;
            top: 2px;
            right: 2px;
            height: 90%;
            border-radius: 2px;

            &:hover {
                background: #f3f2f1;
            }
        }
        .searchBoxInDropdown {
            padding-right: 32px;
        }
    }
}
.ms-Dropdown-item {
    padding: 0 !important;
}

.filterWrapper.toggle {
    padding: 0;
    // margin-right: 1rem;

    h2 {
        width: 100%;
        margin: 0;
        button {
            float: right;
            margin: calc(-1 * ($spacer / 2));
            cursor: pointer;

            &.toggle {
                float: none;
                margin-left: 6px;
                transform: translateY(3px);
            }
        }
    }

    .body {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row !important;
        flex-grow: 0 !important;
        gap: 0 $spacer;

        margin: 1rem;
        margin-top: 0;
        padding-top: 0.4rem;
    
        max-height: 150px;
        transition: all 0.25s linear;
    }

    &.closed {
        .body {
            max-height: 0;
            margin: 0;
            padding: 0;
            overflow: hidden;
        }
    }
} 
