.NoAccessDialog {
    :global {
        .ms-MessageBar-innerText {
            font-size: 16px;
            line-height: 22px;
            margin-top: -13px;
            margin-left: 3px;
        }
    }
}
